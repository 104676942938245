import locationIcon from "../assets/images/svg/location.svg";
import phoneIcon from "../assets/images/svg/phon.svg";
import messageIcon from "../assets/images/svg/message.svg";
import packing from "../assets/images/webp/packing.webp";
import houseShifting from "../assets/images/webp/house-shifting.webp";
import localShifting from "../assets/images/webp/local-shifiting.webp";
import officeShifting from "../assets/images/webp/office-shifting.webp";
import carTransportation from "../assets/images/webp/car-transportation.webp";
import warehouse from "../assets/images/webp/warehouse.webp";
import share from "../assets/images/webp/share-icon.webp";
import moving from "../assets/images/webp/moving-icon.webp";
import schedule from "../assets/images/webp/scheduele-icon.webp";
import go from "../assets/images/webp/go-icon.webp";
import van from "../assets/images/webp/van.webp";
import support from "../assets/images/webp/support.webp";
import cost from "../assets/images/webp/cost.webp";
import safe from "../assets/images/webp/safe.webp";
import door from "../assets/images/webp/door.webp";
import easy from "../assets/images/webp/easy.webp";
import team from "../assets/images/webp/team.webp";
import delivery from "../assets/images/webp/delivery.webp";

import {
  ABOUT_PATH_LINK,
  ABOUT_TEXT_PATH_LINK,
  ContactUs_PATH_LINK,
  ContactUs_TEXT_PATH_LINK,
  NETWORK_PATH_LINK,
  NETWORK_TEXT_PATH_LINK,
  PAYMENT_PATH_LINK,
  PAYMENT_TEXT_PATH_LINK,
  SERVICE_PATH_LINK,
  SERVICE_TEXT_PATH_LINK,
  SERVICES_TEXT_PATH_LINK,
  TRACKING_PATH_LINK,
  TRACKING_TEXT_PATH_LINK,
} from "./constants";
import {
  INFO_LOC_ICON,
  INFO_MAIL_ICON,
  INFO_PHONE_ICON,
} from "../components/common/Icon";

export const NAV_ITEMS_DATA = [
  {
    href: "/",
    text: "Home",
    className: "navItems",
  },
  {
    href: ABOUT_PATH_LINK,
    text: "About Us",
    className: "navItems",
  },
  {
    href: SERVICE_PATH_LINK,
    text: "Our Services",
    className: "navItems",
  },
  {
    href: TRACKING_PATH_LINK,
    text: "Tracking",
    className: "navItems",
  },
  {
    href: NETWORK_PATH_LINK,
    text: "Our Network",
    className: "navItems",
  },
  {
    href: PAYMENT_PATH_LINK,
    text: "Online Payment",
    className: "navItems",
  },
  {
    href: ContactUs_PATH_LINK,
    text: "Contact Us",
    className: "navItems",
  },
];
export const FOOTER_LINKS_DATA = [
  {
    title: "Home",
    footerItems: [
      {
        listItemName: "About Us",
        pathUrl: ABOUT_TEXT_PATH_LINK,
      },
      {
        listItemName: "Our Services",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
      {
        listItemName: "Tracking",
        pathUrl: TRACKING_TEXT_PATH_LINK,
      },
      {
        listItemName: "Our Network",
        pathUrl: NETWORK_TEXT_PATH_LINK,
      },
      {
        listItemName: "Online Payment",
        pathUrl: PAYMENT_TEXT_PATH_LINK,
      },
      {
        listItemName: "Contact Us",
        pathUrl: ContactUs_TEXT_PATH_LINK,
      },
    ],
  },
  {
    title: "Our Services",
    footerItems: [
      {
        listItemName: "House Shifting",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
      {
        listItemName: "Office Shifting",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
      {
        listItemName: "Local Shifting",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
      {
        listItemName: "Car Transportation",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
      {
        listItemName: "Warehouse and Storage",
        pathUrl: SERVICES_TEXT_PATH_LINK,
      },
    ],
  },
  {
    title: "Our Address",
    contact: [
      {
        icon: locationIcon,
        value:
          "623/2, BETORA, PADAL ,PONDA, Curti, South Goa, Goa 403401, India",
        href: "https://www.google.com/maps?q=23.09302,77.11054",
      },
      {
        icon: phoneIcon,
        value: "+91 97281 82882",
        href: "tel:+91 97281 82882",
      },
      {
        icon: messageIcon,
        value: "info@agarwalshiftingpackers.com",
        href: "mailto:info@agarwalshiftingpackers.com",
      },
    ],
  },
];

export const SLIDER_CARDS_DATA = [
  {
    image: packing,
    imageAlt: "packing",
    title: "Packing and Moving",
    content:
      "We have Well trained and Experienced Packing Staff for safe and Secure Packing & Moving.",
    description:
      "Looking for reliable and professional Packing and Moving Services in South Goa? If yes, hire Agarwal Shifting Most trustworthy and affordable moving services.",
  },
  {
    image: localShifting,
    imageAlt: "localShifting",
    title: "Local Shifting Services",
    content:
      "loading & unloading, house shifting and office shifting. We ensure safe, Secure and quick shifting.",
    description:
      "Hire Professinal packers and movers within South Goa for local shifting Services. Local Packers and Movers in South Goa offer budget-friendly packing & unpacking.",
  },
  {
    image: houseShifting,
    imageAlt: "houseShifting",
    title: "House Shifting Services",
    content:
      "We can help you to fulfill your residential relocation needs in South Goa at very affordable and genuine rates.",
    description:
      "Searching for house shifting services in South Goa? We at Agarwal Shifting expert in providing best house shifting services in South Goa.",
  },
  {
    image: officeShifting,
    imageAlt: "officeShifting",
    title: "Office Shifting Services",
    content: "we will help you to move safely and efficiently.",
    description:
      "If you are looking for the affordable office shifting services in South Goa, then we at Agarwal Shifting can be the best choice for you. Whether you want to shift your office locally within city or across India.",
  },
  {
    image: carTransportation,
    imageAlt: "carTransportation",
    title: "Car Transportation Service",
    content:
      "We offer Door-To-Door fast and efficient Car Relocation Services in South Goa at reliable cost.",
    description:
      "Are you looking for Car Transportation services from South Goa to any other City of India? Agarwal Shifting is one of the top car transport companies in South Goa.",
  },
  {
    image: warehouse,
    imageAlt: "warehouse",
    title: "Warehouse and Storage",
    content:
      "goods and commercial goods at best price. We ensure safety and security of your Goods.",
    description:
      "Looking for warehouse and storage services in South Goa? Contact Agarwal Shifting, we offer Short-term storage and long-term storage service for house-hold ",
  },
];
export const PROCESS_CARDS_DATA = [
  {
    image: share,
    title: "Share your Moving needs",
    description: "Let us know where and when would you like to move.",
  },
  {
    image: moving,
    title: "Get Free Moving Estimate",
    description: "We Ensured best price quote for your moving immediately.",
  },
  {
    image: schedule,
    title: "Schedule and Confirm",
    description: "Schedule date and pay a token amount to confirm your move.",
  },
  {
    image: go,
    title: "We get you moved!",
    description: "We will arive as per schedule to pack & move your goods.",
  },
];
export const WHY_US_CARDS_DATA = [
  {
    image: van,
    title: "SWide variety of transportation services",
  },
  {
    image: support,
    title: "24/7 Customer Support",
  },
  {
    image: cost,
    title: "Affordable Cost",
  },
  {
    image: safe,
    title: "Safe and Economical Service",
  },
  {
    image: door,
    title: "All India Door to Door Service",
  },
  {
    image: easy,
    title: "Easy Payment Options",
  },
  {
    image: team,
    title: "Highly Experienced and professional Team",
  },
  {
    image: delivery,
    title: "On-time Delivery",
  },
];

export const MOVE_SIZE_DATA = ["1 BHK", "2 BHK", "3 BHK", "Villa"];
export const PAYMENT_DATA_LIST = [
  {
    name: "NAME",
    value: "AGARWAL SHIFTING PACKER AND MOVERS",
  },
  {
    name: "A/C",
    value: "2186202100001029",
  },
  {
    name: "Bank",
    value: "Punjab National Bank",
  },
  {
    name: "Branch",
    value: "Ponda",
  },
  {
    name: "IFSC Code",
    value: "PUNB0218620",
  },
  {
    name: "Mobile Number",
    value: "+91 97281 82882",
  },
];

export const INFO_DATA = [
  {
    icon: INFO_PHONE_ICON,
    value: "+91 97281 82882",
    url: "tel:+91 97281 82882",
  },
  {
    icon: INFO_MAIL_ICON,
    value: "info@agarwalshiftingpackers.com",
    url: "mailto:info@agarwalshiftingpackers.com",
  },
  {
    icon: INFO_LOC_ICON,
    value: "623/2, BETORA, PADAL ,PONDA, Curti, South Goa, Goa 403401, India",
    url: "https://maps.app.goo.gl/Dvkxv6eTy4krqwYE6",
  },
];
